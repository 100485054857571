import React, { useState, useRef, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";

function GetStarted() {
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleSubmit = (event: Event) => {
      event.preventDefault();
      const formData = new FormData(formRef.current as HTMLFormElement);

      fetch(
        "https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&id=5293e68a78&f_id=007fcbe5f0",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          console.log("Form submitted successfully!", response);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
      navigate("/book-onboarding");
    };

    if (formRef.current) {
      formRef.current.addEventListener("submit", handleSubmit);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener("submit", handleSubmit);
      }
    };
  }, []);

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col lg:flex-row lg:gap-32 gap-16">
        <div className="flex flex-col align-left lg:w-[60%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Join Aglide
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Get Started
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            To get started with Aglide, we will connect you with a member of our team to help you get set up.
          </h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
        <div className="lg:w-[40%] w-full h-fit flex flex-col gap-4">
          <div className="">
          <form
              ref={formRef}
              onSubmit={(e) => e.preventDefault()}
              className="flex flex-col gap-4 w-full"
            >
              <div className="flex gap-4 flex-col lg:flex-row flex-1">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey lg:w-[50%] flex-1"
                  id="mce-FIRSTNAME"
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey lg:w-[50%] flex-1"
                  id="mce-LASTNAME"
                  required
                />
              </div>
              <input
                type="email"
                name="EMAIL"
                placeholder="Work Email Address"
                className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey flex-1"
                id="mce-EMAIL"
                required
              />
              <button
                type="submit"
                className="font-regular text-md h-[59px] px-3 py-2 bg-primary text-white rounded-lg hover:opacity-80 border-primary border-2"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
              >
                Continue
              </button>
              <p className="text-center text-sm text-darkgrey">
                By clicking "Continue" you agree to{" "}
                <a
                  href="/privacy-policy"
                  className=" underline hover:opacity-50 cursor-pointer"
                  target="_blank"
                >
                  Aglide's Privacy Notice
                </a>
                .
              </p>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
    // <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
    //   <Navbar />
    //   <div className="flex flex-col lg:flex-row min-h-screen w-full max-w-7xl mx-auto gap-16 pt-48">
    //     <div className="flex flex-col lg:w-[50%] w-full">
    //       <div className="flex flex-col w-full max-w-[550px] mx-auto gap-8">
    //         <h1 className="text-center lg:text-3xl text-xl font-medium text-black">
    //           Are you ready to get started?
    //         </h1>
    //         <h3 className="text-center lg:text-xl text-lg text-darkgrey">
    //           Aglide let's you managing access to, and setup SSO for any app or
    //           service, while magically organising your organisation and team's
    //           SaaS.
    //         </h3>
    //         <h3 className="text-center lg:text-xl text-lg text-darkgrey">
    //           To register your organisation, you must be a Google Workspace
    //           Admin.
    //         </h3>
    //       </div>
    //     </div>
    //     <div className="flex flex-col lg:w-[50%] w-full mx-auto mb-8">
    //       <div className="flex flex-col max-w-[650px] mx-auto gap-8">
    //         <form
    //           ref={formRef}
    //           onSubmit={(e) => e.preventDefault()}
    //           className="flex flex-col max-w-[650px] gap-4 w-full md:max-w-md"
    //         >
    //           <div className="flex gap-4 flex-col lg:flex-row flex-1 max-w-[650px]">
    //             <input
    //               type="text"
    //               name="firstName"
    //               placeholder="First Name"
    //               className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey lg:w-[50%] flex-1"
    //               id="mce-FIRSTNAME"
    //               required
    //             />
    //             <input
    //               type="text"
    //               name="lastName"
    //               placeholder="Last Name"
    //               className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey lg:w-[50%] flex-1"
    //               id="mce-LASTNAME"
    //               required
    //             />
    //           </div>
    //           <input
    //             type="email"
    //             name="EMAIL"
    //             placeholder="Work Email Address"
    //             className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey flex-1"
    //             id="mce-EMAIL"
    //             required
    //           />
    //           <button
    //             type="submit"
    //             className="font-regular text-md h-[59px] px-3 py-2 bg-primary text-white rounded-lg hover:opacity-80 border-primary border-2"
    //             value="Subscribe"
    //             name="subscribe"
    //             id="mc-embedded-subscribe"
    //           >
    //             Continue
    //           </button>
    //           <p className="text-center text-sm text-darkgrey">
    //             By clicking "Continue" you agree to{" "}
    //             <a
    //               href="/privacy-policy"
    //               className=" underline hover:opacity-50 cursor-pointer"
    //               target="_blank"
    //             >
    //               Aglide's Privacy Notice
    //             </a>
    //             .
    //           </p>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
  );
}

export default GetStarted;
