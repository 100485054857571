import React, { useState, useEffect, useRef } from "react";
import FeatureSection from "../atoms/FeatureSection";
import {
  FaMoneyBill,
  FaGhost,
  FaClock,
  FaLock,
  FaDoorOpen,
  FaIcicles,
  FaSnowflake,
  FaShieldVirus,
  FaShieldAlt,
  FaDoorClosed,
  FaKey,
} from "react-icons/fa";
import PillButton from "../atoms/PillButton";

function SSOFeatureSelector() {
  const [feature, setFeature] = useState(0);

  const touchStartX = useRef<number | null>(null);
  const touchEndX = useRef<number | null>(null);

  useEffect(() => {
    const handleSwipe = () => {
      if (touchStartX.current && touchEndX.current) {
        const diff = touchStartX.current - touchEndX.current;
        const sensitivity = 50; // Adjust this value to control swipe sensitivity

        if (diff > sensitivity && feature < 3) {
          setFeature(feature + 1);
        } else if (diff < -sensitivity && feature > 0) {
          setFeature(feature - 1);
        } else if (diff > sensitivity && feature === 3) {
          setFeature(0);
        } else if (diff < -sensitivity && feature === 0) {
          setFeature(3);
        }
      }
    };

    document.addEventListener("touchstart", (event) => {
      touchStartX.current = event.touches[0].clientX;
    });

    document.addEventListener("touchend", (event) => {
      touchEndX.current = event.changedTouches[0].clientX;
      handleSwipe();
    });

    return () => {
      document.removeEventListener("touchstart", () => {});
      document.removeEventListener("touchend", () => {});
    };
  }, [feature]);

  return (
    <div className="w-full max-w-7xl mx-auto" id="controlpoint">
      <h1 className="lg:text-4xl text-2xl font-medium text-black mb-8 text-center w-full">
        All the same access management.
      </h1>
      <div className="hidden md:flex gap-4 w-7xl mb-4">
        <div
          className={
            "flex flex-col gap-2 flex-1 p-4 rounded-xl cursor-pointer border border-2 border-palegrey " +
            (feature === 0
              ? "border border-2 border-primary5 bg-primary5"
              : "hover:bg-palegrey")
          }
          onClick={() => setFeature(0)}
        >
          <FaShieldAlt />
          Conditional Access Policies
        </div>
        <div
          className={
            "flex flex-col gap-2 flex-1 p-4 rounded-xl cursor-pointer border border-2 border-palegrey " +
            (feature === 1
              ? "border border-2 border-primary5 bg-primary5"
              : "hover:bg-palegrey")
          }
          onClick={() => setFeature(1)}
        >
          <FaSnowflake />
          Instant Access Revoking
        </div>
        <div
          className={
            "flex flex-col gap-2 flex-1 p-4 rounded-xl cursor-pointer border border-2 border-palegrey " +
            (feature === 2
              ? "border border-2 border-primary5 bg-primary5"
              : "hover:bg-palegrey")
          }
          onClick={() => setFeature(2)}
        >
          <FaDoorClosed />
          Only Access Through Aglide
        </div>
        <div
          className={
            "flex flex-col gap-2 flex-1 p-4 rounded-xl cursor-pointer border border-2 border-palegrey " +
            (feature === 3
              ? "border border-2 border-primary5 bg-primary5"
              : "hover:bg-palegrey")
          }
          onClick={() => setFeature(3)}
        >
          <FaKey />
          Secure Zero Trust Encryption
        </div>
      </div>

      <FeatureSection
        title="Apply powerful conditional access policies."
        body="Aglide let's you control when and how your end users are able to access their apps. Limit access to managed devices, require VPNs, or apply georestrictions."
        image="/media/SSO/ConditionalAccess.png"
        visible={feature === 0}
        icon={<FaShieldAlt />}
      />

      <FeatureSection
        title="Instantly freeze a user's access to everything."
        body="With one click, you can revoke all of a user's sessions - instantly locking them out of everything from their emails to the company's social media accounts."
        image="/media/SSO/Freeze.png"
        visible={feature === 1}
        icon={<FaSnowflake />}
      />

      <FeatureSection
        title="Aglide apps are only accessible through Aglide."
        body="Aglide automatically sets account passwords to values the end user can't see, and can't change - ensuring that they can only access their apps through Aglide."
        image="/media/SSO/OnlyAglide.png"
        visible={feature === 2}
        icon={<FaDoorClosed />}
      />

      <FeatureSection
        title="Apps, only accessible to you."
        body="Unlike some SAML based systems, Aglide uses a zero trust encryption model - meaning that no one except you and your users can access accounts."
        image="/media/SSO/Encrypt.png"
        visible={feature === 3}
        icon={<FaKey />}
      />

      <div className="mt-4 flex md:hidden flex-row justify-center">
        {[...Array(4)].map((_, index) => (
          <div
            key={index}
            className={
              "w-2 h-2 rounded-full mx-1 " +
              (feature === index ? "bg-grey" : "bg-lightgrey")
            }
          ></div>
        ))}
      </div>
    </div>
  );
}

export default SSOFeatureSelector;
