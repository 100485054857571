import React, { useState, useRef, useEffect, FormEvent } from "react";
import { InlineWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";
import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";

function BookOnboarding() {
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleSubmit = (event: Event) => {
      event.preventDefault();
      const formData = new FormData(formRef.current as HTMLFormElement);

      fetch(
        "https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&id=5293e68a78&f_id=007fcbe5f0",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          console.log("Form submitted successfully!", response);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
      navigate("/book-onboarding");
    };

    if (formRef.current) {
      formRef.current.addEventListener("submit", handleSubmit);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener("submit", handleSubmit);
      }
    };
  }, []);

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col lg:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[60%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Get Started
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Book onboarding
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            One of our team will take you through setup, answer your specific questions and give you a guided tour of the product.
          </h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
        <div className="lg:w-[40%] w-full h-fit flex flex-col gap-4">
          <div className="border-2 border-lightgrey rounded-2xl overflow-hidden mb-8">
            <InlineWidget
              url="https://calendly.com/d/39j-mbc-48c/aglide-white-glove-onboarding"
              styles={{
                width: "100%",
                maxWidth: "500px",
                marginLeft: "auto",
                marginRight: "auto",
                height: 600,
              }}
              pageSettings={{
                backgroundColor: "ffffff",
                hideEventTypeDetails: true,
                hideLandingPageDetails: true,
                primaryColor: "20513b",
                textColor: "000c06",
              }}
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BookOnboarding;
