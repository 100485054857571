import React, { useState } from 'react';
import RoundedSectionSM from '../atoms/RoundedSectionSM';
import SubmitButton from '../atoms/SubmitButton';


function ProofButton() {
  const [showProof, setShowProof] = useState(false);
  return (
    <>
      <SubmitButton text="I don't believe this works" onClick={() => setShowProof(true)} type="tertiary" />

      <div className="fixed inset-0 z-50" style={{ display: showProof ? 'block' : 'none' }}>
        <div className="overflow-hidden bg-white/30 backdrop-blur-lg animate-fade-in-0.15">

          <div className="flex items-center justify-center h-full p-4">
            <div className="rounded-lg h-[100vh] w-full overflow-y-auto">
              <div className="flex flex-col md:flex-row items-start justify-between p-6 lg:gap-40 md:gap-16 gap-8">
                <div className="flex flex-col w-full md:w-1/2 gap-8">
                  <h1 className="text-2xl lg:text-4xl font-medium text-black">
                    Many don't believe that Aglide can enforce true SSO without SAML. Here is proof that it can.
                  </h1>
                  <h3 className="text-xl lg:text-2xl text-darkgrey">
                    "The claims that made about your product sounds like voodoo - SAMLless SSO for any SaaS, in such a way that end-users can't see or change account credentials and prevents access to the account without going through Aglide. How can that possibly be accomplished?" - Anonymous Reddit User
                  </h3>
                  <div className='flex gap-4'>
                    <SubmitButton
                      type="primary"
                      text="Book Demo"
                      onClick={() => (window.location.href = "/book-demo")}
                    />
                    <SubmitButton
                      type="secondary"
                      text="Close"
                      onClick={() => setShowProof(false)}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full md:w-fit gap-8 rounded-lg">
                  <video controls loop className="w-full max-h-[90vh] min-h-[530px] rounded-lg bg-black" src={"/media/AglideProofVideo.mp4"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProofButton;