import { Helmet } from "react-helmet";
import RoundedSection from "../components/atoms/RoundedSection";
import SubmitButton from "../components/atoms/SubmitButton";
import ConnectedIAMs from "../components/organisms/ConnectedIAMs";
import Footer from "../components/organisms/Footer";
import SSOFeatureSelector from "../components/organisms/SSOFeatureSelector";
import Navbar from "../components/organisms/navbar";

function AutoMigrate() {
  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Helmet>
        <title>Migrating to Aglide SSO</title>
        <meta name="description" content="Convert your old unmanaged accounts into secure Aglide SSO apps, in seconds." />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Migrating to Aglide SSO",
            "description": "Convert your old unmanaged accounts into secure Aglide SSO apps in seconds.",
            "image": ""
          }
        `}
        </script>
      </Helmet>

      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Auto Migrate
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Convert accounts into SSO apps, in seconds.
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Auto Migrate automates the process of converting your old accounts into managed SSO apps, meaning your whole org can be live in a day.</h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-48 mb-36">

        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto">
            <div className="flex lg:w-[33%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                From unmanaged to locked down.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Mark a user or group as having an account, and Aglide will automatically convert it into a managed SSO app.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Everything will be configured to the most secure settings, and the app will be ready to use in seconds.
                </p>
              </div>
            </div>

            <div
              className={`w-[100%] lg:w-[66%] overflow-visible m-auto align-middle`}
            >
              <div className="w-[1200px] hidden lg:flex">
                <img
                  src={"/media/AutoMigrateDashboard.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >
              <img
                src={"/media/AutoMigrateDashboardSM.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
          </div>
        </div>

        <div id="sso">
          <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
            How does it work?
          </h1>
          <div className="max-w-7xl grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 align-middle mx-auto mt-8">
            <RoundedSection
              title="1. Connect it to your identity provider."
              body="Connect Aglide to your existing provider using SAML & SCIM, to provision Aglide accounts to your users."
              image="/media/HowItWorks/ConnectIdP.png"
            />
            <RoundedSection
              title="2. Queue apps for migration."
              body="Mark all the apps each user has, and they will be migrated on their next sign in."
              image="/media/HowItWorks/LoginDetails.png"
            />
            <RoundedSection
              title="3. Users can only access apps via Aglide."
              body="Once migrated, users will only be able to access their Aglide apps via their SSO credentials."
              image="/media/HowItWorks/Launch.png"
            />
          </div>
        </div>

        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row-reverse align-middle mx-auto">
            <div className="flex lg:w-[40%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                Roll out without disruption.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Users choose when to initiate migration and can continue to use their old login method until they're ready.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  With Auto Migrate, you can roll out SSO user by user, rather then app by app.
                </p>
              </div>
            </div>

            <div
              className={`w-full lg:w-fill m-auto align-middle `}
            >
              <div className="w-full hidden lg:flex">
                <img
                  src={"/media/AutoMigrate.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >

              <img
                src={"/media/AutoMigrate.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
          </div>
        </div>

        <div className="bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col lg:gap-16 gap-8 h-fit max-w-5xl mx-auto md:flex-row w-full">
          <div className="flex-1 order-1">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              Ready to be live in a day?
            </h1>
            <p className="text-left text-l text-darkgrey">
              With Aglide Auto Migrate, customers are live in a day. To learn more about the fastest way to close the holes in your identity, book a demo.
            </p>
            <div className="flex flex-col sm:flex-row justify-left gap-4 mr-auto mt-6">
              <SubmitButton
                type="primary"
                text="Book a demo"
                onClick={() =>
                  (window.location.href = "/book-demo")
                }
              />
            </div>
          </div>
          <div className="md:w-1/2 w-full order-2 sm:max-w-[400px] mx-auto">
            <img src={"/media/HowItWorks/Launch.png"} alt="onboarding" />
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default AutoMigrate;
