import React from 'react';
import RoundedSectionSM from '../atoms/RoundedSectionSM';


function AglideFeaturesCarousell() {
  return (
    <div id="sso" className="overflow-visible mx-[-48px]">
      <style>{`
        .carousel-container {
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          position: relative;
        }

        .carousel {
          display: flex;
          width: calc(2 * 1830px); /* Combined width of original and duplicate items */
          animation: scroll-left-right 40s linear infinite;
        }

        @keyframes scroll-left-right {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-1830px); /* Move exactly the width of the combined slides */
          }
        }

        .carousel > div {
          display: inline-block;
        }
      `}</style>
      <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
        More than just SSO.
      </h1>
      <div className="carousel-container mt-8">
        <div className="carousel">
        <RoundedSectionSM
            title="Integrated password manager"
            body="Store everything from credit cards to API keys in Aglide’s encrypted vaults."
            image="/media/OtherFeatures/PasswordManager.png"
            overflow={true}
          />
          <RoundedSectionSM
            title="App usage monitoring"
            body="See how frequently each app is used, to spot unnecessary accounts and cut bills."
            image="/media/OtherFeatures/Usage.png"
          />
          <RoundedSectionSM
            title="Shared SSO apps & item vaults"
            body="Shared vaults let multiple users access both SSO apps and password manager items."
            image="/media/OtherFeatures/Shared.png"
          />
          <RoundedSectionSM
            title="Ghost app detection"
            body="Aglide detects unmanaged apps - flagging them to you to get them under control."
            image="/media/OtherFeatures/Ghost.png"
          />
          <RoundedSectionSM
            title="Powerful user grouping tools"
            body="Create user groups, linked to your existings IAM, to bulk set rules and permissions."
            image="/media/OtherFeatures/Groups.png"
          />
          {/* Duplicate the items to create the carousel effect */}
          <RoundedSectionSM
            title="Integrated password manager"
            body="Store everything from credit cards to API keys in Aglide’s encrypted vaults."
            image="/media/OtherFeatures/PasswordManager.png"
            overflow={true}
          />
          <RoundedSectionSM
            title="App usage monitoring"
            body="See how frequently each app is used, to spot unnecessary accounts and cut bills."
            image="/media/OtherFeatures/Usage.png"
          />
          <RoundedSectionSM
            title="Shared SSO apps & item vaults"
            body="Shared vaults let multiple users access both SSO apps and password manager items."
            image="/media/OtherFeatures/Shared.png"
          />
          <RoundedSectionSM
            title="Ghost app detection"
            body="Aglide detects unmanaged apps - flagging them to you to get them under control."
            image="/media/OtherFeatures/Ghost.png"
          />
          <RoundedSectionSM
            title="Powerful user grouping tools"
            body="Create user groups, linked to your existings IAM, to bulk set rules and permissions."
            image="/media/OtherFeatures/Groups.png"
          />

        </div>
      </div>
    </div>
  );
}

export default AglideFeaturesCarousell;