import React, { useState } from "react";
import { IoMdPlay, IoMdPlayCircle } from "react-icons/io";
import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";

function Support() {
  const [demoOpen, setDemoOpen] = useState(false);

  return (
    <div className="min-h-full">
      <Navbar />
      <div className="container mx-auto py-8 mt-32 mb-8 px-6 flex gap-1 flex-col">
        <h1 className="text-3xl font-medium mb-4">Support</h1>
        <h2 className="text-xl font-medium text-primary">By Email</h2>
        <p>
          Please feel free to reach out to{" "}
          <a className=" font-medium" href="mailto:support@aglide.com">
            support@aglide.com
          </a>{" "}
          for any support queries. We endeavour to get back to you within 24
          hours.
        </p>
        <p>
          Due to the secure nature of our product, we have limited visibility
          for support queries. Please try to include as much detail, including
          screenshots (without revealing sensitive information) if possible.
        </p>
        <h2 className="text-xl font-medium text-primary mt-4">By Slack</h2>
        <p>
          If you would prefer to be added to a personal Slack channel for
          support, please{" "}
          <a className=" font-medium" href="mailto:support@aglide.com">
            let us know
          </a>
          . We find this method of support is more effective, and may allow us
          to resolve your query faster.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Support;
