import RoundedSection from "../components/atoms/RoundedSection";
import SubmitButton from "../components/atoms/SubmitButton";
import ConnectedIAMs from "../components/organisms/ConnectedIAMs";
import Footer from "../components/organisms/Footer";
import SSOFeatureSelector from "../components/organisms/SSOFeatureSelector";
import { Helmet } from 'react-helmet';
import Navbar from "../components/organisms/navbar";
import ProofButton from "../components/organisms/ProofButton";

function IdPConnect() {
  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Helmet>
        <title>Aglide + Your Identity Provider</title>
        <meta name="description" content="Add Aglide apps directly to your existing single sign on" />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Aglide + Your Identity Provider",
            "description": "Add Aglide SSO apps directly to your existing identity provider",
            "image": ""
          }
        `}
        </script>
      </Helmet>

      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            IdP Connect
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Aglide apps, right inside your existing SSO.
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Aglide works seamlessly with your existing environment, letting you easily sync users and groups and nest Aglide apps into your current SSO launcher.</h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-48 mb-36">

        <ConnectedIAMs onHome={false} />

        <div id="sso">
          <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
            Setup couldn't be easier.
          </h1>
          <div className="max-w-7xl grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 align-middle mx-auto mt-8">
            <RoundedSection
              title="1. Connect it to your identity provider."
              body="Connect Aglide to your existing provider using SAML & SCIM, to provision Aglide accounts to your users."
              image="/media/HowItWorks/ConnectIdP.png"
            />
            <RoundedSection
              title="2. Queue apps for migration."
              body="Mark all the apps each user has, and they will be migrated on their next sign in."
              image="/media/HowItWorks/LoginDetails.png"
            />
            <RoundedSection
              title="3. Users only access apps via SSO."
              body="Once migrated, users will only be able to access their Aglide apps via their SSO credentials."
              image="/media/HowItWorks/Launch.png"
            />
          </div>
          <div className="max-w-7xl flex justify-end mx-auto mt-8">
            <SubmitButton
              type="primary"
              text="Auto Migrate"
              onClick={() =>
                (window.location.href = "/auto-migrate")
              }
            />
          </div>
        </div>

        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto">
            <div className="flex lg:w-[33%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                Sync your users and groups.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Aglide connects to your existing identity provider using SAML and SCIM.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  With the services connected, you can instantly sync all your users and groups.
                </p>
              </div>
            </div>

            <div
              className={`w-[100%] lg:w-[66%] overflow-visible m-auto align-middle`}
            >
              <div className="w-[1200px] hidden lg:flex">
                <img
                  src={"/media/IdPLogos/OtherGrid.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >

              <img
                src={"/media/IdPLogos/OtherGridSM.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
          </div>
        </div>

        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto lg:gap-12">
            <div className="flex lg:w-[50%] w-full flex-col gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                Finally every app is in your SSO.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  With Aglide, you can finally get every app managed by and accessed from your SSO provider.
                </p>
              </div>
              <div className="hidden lg:flex ">
                <ProofButton />
              </div>
            </div>

            <div
              className={`w-full lg:w-[50%] m-auto align-middle `}
            >
              <div className="w-full hidden lg:flex">
                <img
                  src={"/media/IdPPlusAglide.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2`}
            >

              <img
                src={"/media/IdPPlusAglide.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
            <div className="flex lg:hidden w-full justify-end mt-6">
              <ProofButton />
            </div>
          </div>
        </div>

        <div className="bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col lg:gap-16 gap-8 h-fit max-w-5xl mx-auto md:flex-row w-full">
          <div className="flex-1 order-1">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              Ready to supercharge your SSO?
            </h1>
            <p className="text-left text-l text-darkgrey">
              Book a demo with us and see how Aglide can fill in the gaps of your identity environment.
            </p>
            <div className="flex flex-col sm:flex-row justify-left gap-4 mr-auto mt-6">
              <SubmitButton
                type="primary"
                text="Book a demo"
                onClick={() =>
                  (window.location.href = "/book-demo")
                }
              />
            </div>
          </div>
          <div className="md:w-1/2 w-full order-2 sm:max-w-[400px] mx-auto">
            <img src={"/media/HowItWorks/ConnectIdP.png"} alt="onboarding" />
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default IdPConnect;
