function RoundedSectionSM(props: {
  image?: string;
  body: string;
  title: string;
  overflow?: boolean;
}) {
  return (
    <div className="flex w-[350px] h-[422.25px] ml-[16px] rounded-lg bg-palegrey overflow-hidden">
      <div className="p-6 pb-4">
        <p className="whitespace-normal text-xl font-medium text-black">{props.title}</p>
        <p className="whitespace-normal text-md font-light text-darkgrey">{props.body}</p>
      </div>
      <div className={props.overflow ? "" : "p-6 px-auto"}>
        <img
          src={props.image}
          alt="onboarding"
        />
      </div>
    </div>
  );
}

export default RoundedSectionSM;
