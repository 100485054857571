import React from "react";

function PillButton(props: {
  type: "primary" | "secondary";
  text: string;
  onClick: () => void;
}) {
  if (props.type === "secondary") {
    return (
      <button
        className="font-light text-sm px-3 h-8 md:px-4 md:h-10 bg-white text-darkgrey border-darkgrey border-[1px] rounded-full hover:bg-palegrey"
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  } else {
    return (
      <button
        className="font-light text-sm px-3 h-8 md:px-4 md:h-10 bg-primary text-white rounded-full hover:opacity-80"
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  }
}

export default PillButton;
