import React, { useState } from "react";
import { IoMdPlay, IoMdPlayCircle } from "react-icons/io";
import PillButton from "../components/atoms/PillButton";
import SubmitButton from "../components/atoms/SubmitButton";
import Footer from "../components/organisms/Footer";
import HomeSection from "../components/atoms/FeatureSection";
import Navbar from "../components/organisms/navbar";

function SecurityPolicy() {
  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Effective 27th August, 2024
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Security Policy
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            This document outlines the security measures and practices Aglide
            follows to protect sensitive data. For specific details on how
            Aglide secures your data, please refer to the Security Page.
          </h3>
          <div className="mt-8 flex gap-4"></div>
        </div>
      </div>

      <div className="flex flex-col gap-36 w-full">
        <div className="max-w-7xl mx-auto flex flex-col gap-2 w-full">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              Contents
            </h1>
            <ol className="list-decimal pl-6 whitespace-normal text-md font-light text-darkgrey">
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#infrastructure-and-service-delivery"
                >
                  Aglide’s Infrastructure and Service Delivery
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#hosting-location"
                >
                  Hosting Location of Customer Data
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#encryption"
                >
                  Encryption
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#system-and-network-security"
                >
                  System & Network Security
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#application"
                >
                  Application
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#environment-access"
                >
                  Environment Access
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#monitoring-and-logging"
                >
                  Monitoring & Logging
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#vulnerability-detection"
                >
                  Vulnerability Detection
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#training-and-legal"
                >
                  Training & Legal
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#physical-security"
                >
                  Physical Security
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#incident-detection"
                >
                  Incident Detection & Response
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#customer-responsibilities"
                >
                  Customer Responsibilities
                </a>
              </li>
            </ol>
            <p className="whitespace-normal text-md font-light text-darkgrey pt-8">
              This Security Policy is written to be incorporated into a separate
              written agreement between Aglide and Customer. References this
              document (the “Agreement”) and any terms used but not defined herein shall have the meaning set
              forth in the Agreement. In the event of any conflict between the
              terms of the Agreement and this Policy, this Policy shall govern.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey pt-8">
              Aglide regularly tests and evaluates its Security Program, and may
              review and update this Policy as a result. Such updates shall be
              designed to enhance Aglide's Services or Security Program.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="infrastructure-and-service-delivery"
            >
              Aglide’s Infrastructure and Service Delivery
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              Aglide employs a robust security architecture that combines local
              data processing on the end-users device with server-side
              encryption, all within a zero-trust model. This approach ensures
              that sensitive data is protected at all times, with encryption
              occurring both locally on the user’s device and on Aglide’s cloud
              infrastructure ("Cloud Environment") hosted on Amazon Web Services
              (“AWS”), which includes a NodeJS server and Postgres database.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Local Data Processing: </strong>Operations that involve
              interaction with an end-user's account (e.g., Sign In,
              Provisioning, Privilege Control, etc.), are executed directly on
              the end-user's device.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Zero Trust Encryption: </strong>Aglide uses a Zero Trust
              encryption model to ensure sensitive Customer Data is secure. This
              means that even on Aglide’s servers, data is encrypted in such a
              way that only authorised end-users can decrypt it.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Local & Cloud Environments:</strong> Depending on the
              specific type of data, encryption occurs either within the Cloud
              Environment or on the end-user's device. The zero-trust model
              ensures that even when data is processed or stored on the Cloud
              Environment, it remains inaccessible to unauthorised parties,
              including Aglide itself.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              For more detailed information on how Aglide secures Customer Data,
              please refer to the{" "}
              <a
                href="/security"
                className="underline hover:opacity-50 cursor-pointer"
              >
                Security Page
              </a>
              .
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="hosting-location"
            >
              Hosting Location of Customer Data
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The hosting location of Customer Data is the production Cloud
              Environment in the Republic of Ireland, or such other region
              mutually agreed to by Customer and Aglide, including as identified
              on the Order Form.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="encryption"
            >
              Encryption
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              Aglide employs robust encryption standards to protect Customer
              Data both at-rest and in-transit. Customer Data within the Service
              is encrypted at-rest using AES 256-bit encryption, ensuring that
              data stored on Aglide’s infrastructure remains secure. For data
              in-transit over untrusted networks, Aglide uses Transport Layer
              Security (TLS) 1.2 (or better) to protect Customer Data.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              Aglide’s zero-trust model ensures that sensitive data is encrypted
              in such a way that only the end-user can decrypt it. Encryption
              keys are never stored in plaintext on Aglide’s servers. Depending
              on the specific data and operation, encryption may occur either
              locally on the end-user’s device or in the Cloud Environment.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              For clarity, Aglide has no control over the encryption policies
              established by third-party services or applications that may
              integrate with Aglide, and has no liability in connection
              therewith. Aglide logically separates encryption keys from
              Customer Data, maintaining a clear distinction between data
              storage and key management to enhance security.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              For more detailed information on Aglide’s encryption practices and
              security measures, please refer to the{" "}
              <a
                href="/security"
                className="underline hover:opacity-50 cursor-pointer"
              >
                Security Page
              </a>
              .
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="system-and-network-security"
            >
              System & Network Security
            </h1>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="application"
              >
                Application
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Source Code: </strong>Only approved employees have
                access to Aglide's repositories, and they all must have multi
                factor authentication enabled. Deployment is safeguarded by a
                mandatory pull request (PR) procedure, requiring all code
                changes to undergo peer review.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="environment-access"
              >
                Environment Access
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Access Controls: </strong>Access to Customer Data is
                limited to approved employees, authenticated into their
                corporate Aglide account, with a unique key. Access to the Cloud
                Environment is restricted to a limited number of Aglide
                employees, and is controlled by AWS IAM policies. Aglide's Zero
                Trust architecture ensures that even approved Aglide employees
                cannot access sensitive Customer Data.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Environment Isolation: </strong>Customer Data is never
                stored in non-production environments. Customer accounts are
                logically separated in Aglide's production environment. Aglide
                has separate development, staging and production environments.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="monitoring-and-logging"
              >
                Monitoring & Logging
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Network Monitoring: </strong>Systems and alarms are
                setup to monitor the performance and key metrics for Aglide's
                servers, database, and other network components. These alert
                when services are acting irregularly to help identify issues or
                attacks.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Error Logging: </strong>Aglide captures logs of certain
                activities and changes to detect malicious activity and errors.
                Logs are only accessible to necessary Aglide employees and care
                is taken to ensure that sensitive data is not logged.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>User Logging: </strong>Data about end-users interacting
                with the Aglide Services is collected to improve performance.
                Logs are only accessible to necessary Aglide employees and care
                is taken to ensure sensitive, session-specific data is not
                captured.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="vulnerability-detection"
              >
                Vulnerability Detection
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Vulnerability Scans: </strong>Vulnerability scanning and
                package monitoring are performed on all Aglide infrastructure,
                with services being patched on a regular basis.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Penetration Testing: </strong> Aglide will conduct
                regular penetration tests to identify and address potential
                vulnerabilities in its infrastructure. These tests will be
                conducted by third-party security experts and are designed to
                simulate real-world attacks.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="administative-controls"
            >
              Administrative Controls
            </h1>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="training-and-legal"
              >
                Training & Legal
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Employee Training: </strong>Aglide maintains a mandatory
                security awareness and training program for its employees.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Confidentiality: </strong>All Aglide employees sign
                non-disclosure and confidentiality agreements.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Responsibility: </strong>Employees are required to sign
                Aglide's information security policy, which includes
                acknowledging responsibility for reporting security incidents
                involving Customer Data.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="privilege-and-access"
              >
                Privilege & Access
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Access Reviews: </strong>Aglide internally leverages the
                principle of Least Privilege for access. Regular access reviews
                are conducted to ensure that continued access to critical systems is
                still required.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Access Controls: </strong>Employees can only access
                Aglide systems through Single Sign On or using credentials
                stored in a password manager, with Multi-Factor Authentication
                enforced where possible. Employees must use high entropy
                passwords and 2-factor authentication to access Single Sign On
                applications or the password manager.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1
                className="whitespace-normal text-xl font-medium text-black"
                id="physical-security"
              >
                Physical Security
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Aglide Premises: </strong>Equipment capable of accessing
                Aglide services is only available in Aglide's office, which is
                secured with a keycard system.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Data Centres: </strong>Aglide defers all data center
                physical security controls to AWS. Details of AWS’s physical
                security controls can be found on their{" "}
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="https://aws.amazon.com/compliance/data-center/controls/"
                >
                  website
                </a>
                .
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="incident-detection"
            >
              Incident Detection & Response
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Reporting: </strong>If Aglide becomes aware of a breach of
              security leading to the accidental or unlawful destruction, loss,
              alteration, unauthorised disclosure of, or access to Customer Data
              (a "Security Incident"), Aglide shall notify Customer without
              undue delay, and in any case, where feasible, notify Customer
              within 72 hours after becoming aware.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Investigation: </strong>In the event of a Security
              Incident as described above, Aglide shall promptly take reasonable
              steps to contain, investigate, and mitigate any Security Incident.
              Any logs determined to be relevant to a Security Incident shall
              be preserved for at least one year.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              <strong>Communication: </strong>Aglide shall provide Customer
              timely information about the Security Incident to the extent known
              to Aglide, including, but not limited to, the nature and
              consequences of the Security Incident, the measures taken and/or
              proposed by Aglide to mitigate or contain the Security Incident,
              the status of Aglide's investigation, a contact point from which
              additional information may be obtained, and the categories and
              approximate number of data records concerned. Notwithstanding the
              foregoing, Customer acknowledges that because Aglide personnel do
              not have visibility to the content of Customer Data, it will be
              unlikely that Aglide can provide information as to the particular
              nature of the Customer Data, or where applicable, the identities,
              number, or categories of affected data subjects. Communications by
              or on behalf of Aglide with Customer in connection with a Security
              Incident shall not be construed as an acknowledgment by Aglide of
              any fault or liability with respect to the Security Incident.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto mb-48">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1
              className="text-left lg:text-4xl text-2xl font-medium text-black"
              id="customer-responsibilities"
            >
              Customer Responsibilities
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The Customer takes responsibility for managing and protecting its
              end-user roles and credentials, including but not limited to (i)
              requiring that all end-users keep credentials confidential, (ii)
              reporting to Aglide any suspicious activity or if a user
              credential has been compromised, (iii) appropriately configuring
              end-user and role-based access controls, taking into account the
              nature of its Customer Data, and (iv) maintaining appropriate
              password entropy.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The Customer will ensure end-users promptly update client software
              (Aglide Desktop, Aglide Extension).
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The Customer agrees that Aglide is not obligated to assess
              Customer Data for the purposes of identifying compliance with
              legal, regulatory, or other requirements, and the Customer is
              responsible for making appropriate use of the Service to ensure a
              level of security appropriate to the particular content of
              Customer Data.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The Customer will appropriately manage and protect any
              Customer-managed encryption keys, including but not limited to
              administrative recovery keys, to ensure the integrity,
              availability, and confidentiality of the key and Customer Data
              encrypted with such key.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default SecurityPolicy;
