import React, { useState, useRef, useEffect, FormEvent } from "react";
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom";
import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";

function Research() {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleSubmit = async (event: Event) => {
      event.preventDefault();
      setLoading(true);
      const formData = new FormData(formRef.current as HTMLFormElement);
      try {
        const response = await fetch(
          "https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&id=5293e68a78&f_id=007fcbe5f0",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          console.log("Form submitted successfully!", response);
        } else {
          console.error("Error submitting form:", response.statusText);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
      // Download the file
      const downloadUrl = '/media/Aglide - State of Identity Practice 2024.pdf'; // Replace with your file URL
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'Aglide - State of Identity Practice 2024.pdf'); // Replace 'filename.pdf' with the desired file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    };

    if (formRef.current) {
      formRef.current.addEventListener("submit", handleSubmit);
    }
    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener("submit", handleSubmit);
      }
    };
  }, []);

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col lg:flex-row lg:gap-32 gap-16">
        <div className="flex flex-col align-left lg:w-[60%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Aglide Research
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            State of Identity
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey mb-4">
            State of Identity 2024 was a study led by Eimhin O’Neill and supported by Aglide, researching the identity practices and policies of 59 leading CISOs, Heads of IT and System Administrators.
          </h3>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
        <div className="lg:w-[40%] w-full h-fit flex flex-col gap-4">
          <div className="h-full w-full rounded-3xl align-middle mx-auto bg-primary5 max-w-[500px]">
            <div className="p-8 h-full w-full flex flex-col align-middle mx-auto justify-between lg:p-10">
              <h3 className="mx-1 lg:text-2xl text-xl font-medium ">
                Download the report
              </h3>
              <div className="mt-4">
                <form
                  ref={formRef}
                  onSubmit={(e) => e.preventDefault()}
                  className="flex flex-col gap-4 w-full"
                >
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey flex-1"
                    id="mce-FIRSTNAME"
                    required
                  />
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey flex-1"
                    id="mce-LASTNAME"
                    required
                  />

                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="Work Email Address"
                    className="text-md placeholder-grey p-4 border-[1.5px] border-grey rounded-lg outline-darkgrey flex-1"
                    id="mce-EMAIL"
                    required
                  />
                  <button
                    type="submit"
                    className="font-regular text-md h-[59px] px-3 py-2 bg-primary text-white rounded-lg hover:opacity-80 border-primary border-2"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                  >
                    {loading ? (
                      <MoonLoader
                        color="#ffffff"
                        cssOverride={{
                          display: "flex",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        loading
                        size={18}
                        speedMultiplier={0.75}
                      />
                    ) : "Download"}
                  </button>
                  <p className="text-center text-sm text-darkgrey">
                    By clicking "Continue" you agree to{" "}
                    <a
                      href="/privacy-policy"
                      className=" underline hover:opacity-50 cursor-pointer"
                      target="_blank"
                    >
                      Aglide's Privacy Notice
                    </a>
                    .
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div >
  );
}

export default Research;
