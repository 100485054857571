import { useState } from "react";
import SubmitButton from "../atoms/SubmitButton";

function ConnectedIAMs(props: { onHome: Boolean }) {
  const [idp, setIdp] = useState("okta");

  const homeIdPs: any = {
    "okta": { name: "Okta", logo: "OktaLogo", dashboard: "OktaGrid", dashboardSM: "OktaGridSM" },
    "onelogin": { name: "OneLogin", logo: "OneLoginLogo", dashboard: "OneLoginGrid", dashboardSM: "OneLoginGridSM" },
    "entra": { name: "Microsoft Entra", logo: "EntraLogo", dashboard: "EntraGrid", dashboardSM: "EntraGridSM" },
    "google": { name: "Google Workspace", logo: "GoogleLogo", dashboard: "GoogleGrid", dashboardSM: "GoogleGridSM" },
    "other": { name: "Other", dashboard: "OtherGrid", dashboardSM: "OtherGridSM" },
    "aglide": { name: "None", dashboard: "AglideGrid", dashboardSM: "AglideGridSM" },
  }

  const pageIdPs: any = {
    "okta": { name: "Okta", logo: "OktaLogo", dashboard: "OktaGrid", dashboardSM: "OktaGridSM" },
    "onelogin": { name: "OneLogin", logo: "OneLoginLogo", dashboard: "OneLoginGrid", dashboardSM: "OneLoginGridSM" },
    "entra": { name: "Microsoft Entra", logo: "EntraLogo", dashboard: "EntraGrid", dashboardSM: "EntraGridSM" },
    "google": { name: "Google Workspace", logo: "GoogleLogo", dashboard: "GoogleGrid", dashboardSM: "GoogleGridSM" },
    "other": { name: "Other", dashboard: "OtherGrid", dashboardSM: "OtherGridSM" },
  }

  return (
    <div className="md:h-[960px] w-full align-middle mx-auto overflow-visible relative" id="IdPConnect">
      <div className="max-w-7x h-fit flex flex-col items-center justify-center align-middle mx-auto relative gap-6">
        <div className="flex lg:gap-3 md:gap-2 gap-1 p-3 max-w-[425px] md:max-w-none w-fit bg-gradient-to-tr from-[#F3F9F6] to-[#E6F2ED] rounded-[16px] z-10">
          {Object.keys(props.onHome ? homeIdPs : pageIdPs).map((key: string) => (
            <div
              key={key}
              className={
                "lg:px-6 md:px-4 px-2 lg:py-4 md:py-3 py-1 lg:w-36 md:w-28 grid items-center cursor-pointer justify-center text-md rounded-[8px] hover:backdrop-blur-md hover:bg-white/40 " +
                (idp === key ? "shadow-lg backdrop-blur-md bg-white/70 " : "") +
                (idp === "google" ? " " : " ") +
                ((key === "google" && props.onHome) ? "hidden md:block" : "")
              }
              onClick={() => setIdp(key)}
            >
              {(props.onHome ? homeIdPs : pageIdPs)[key].logo === undefined ? (
                <h3 className="text-center lg:text-xl md:text-lg text-sm text-darkgrey font-medium">
                  {(props.onHome ? homeIdPs : pageIdPs)[key].name}
                </h3>
              ) : (
                <img
                  src={"/media/IdPLogos/" + (props.onHome ? homeIdPs : pageIdPs)[key].logo + (idp === key ? ".png" : "-Grey.png")}
                  className="w-full h-8 object-contain"
                  alt={"logo-" + (props.onHome ? homeIdPs : pageIdPs)[key].name}
                />
              )}
            </div>


          ))}
        </div>
      </div>
      <div className="max-w-7xl h-fit flex flex-col md:flex-row align-middle mx-auto relative">
        <div className="w-[100%] md:w-[60%] lg:w-[64%] h-fit overflow-x-hidden">
          <div className="hidden md:flex w-[1200px] h-[] absolute top-0 right-0">
            <img
              src={"/media/IdPLogos/" + (props.onHome ? homeIdPs : pageIdPs)[idp].dashboard + ".png"}
              className="mx-auto"
              alt={"dashboard-" + (props.onHome ? homeIdPs : pageIdPs)[idp].name}
              style={{ transform: "translateX(-38%)" }}
            />
          </div>
        </div>
        <div className="flex md:w-[63%] lg:w-[30%] w-full flex-col mt-8 gap-2 lg:gap-4 z-10">
          {!props.onHome ? (
            <>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                With Aglide IdP connect, you can nest Aglide apps into your existing SSO dashboard.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                End users can access everything from their existing launcher.
              </p>
            </>
          ) : idp === "other" ? (
            <>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Aglide supports SAML and SCIM as standard.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Admins can sync users and groups from their current identity provider.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Users can access Aglide apps with their existing SSO credentials.
              </p>
              <div className="mt-2 hidden md:flex">
                <SubmitButton
                  type="primary"
                  text="Learn More"
                  onClick={() =>
                    (window.location.href = "/idp-connect")
                  }
                />
              </div>
            </>
          ) : (idp === "aglide") ? (
            <>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Aglide can be used as your sole single sign on provider.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Admins can provision users, create groups, and apply conditional access policies natively within Aglide.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Users can access their apps from the Aglide launcher.
              </p>
            </>
          ) : (
            <>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Admins can sync users and groups from their current identity provider.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Users can access their apps from their existing SSO dashboard.
              </p>
              <p className="text-left lg:text-2xl text-lg text-darkgrey">
                Aglide is designed to fit right in to your unique environment.
              </p>
              <div className="mt-2 hidden md:flex">
                <SubmitButton
                  type="primary"
                  text="Learn More"
                  onClick={() =>
                    (window.location.href = "/idp-connect")
                  }
                />
              </div>
            </>
          )}
        </div>
        <div className="flex md:hidden m-[-24px] mt-8">
          <img
            src={"/media/IdPLogos/" + (props.onHome ? homeIdPs : pageIdPs)[idp].dashboardSM + ".png"}
            className="mx-auto"
            alt="onboarding"
          />
        </div>
        <div className="mt-8 justify-end flex md:hidden">
          <SubmitButton
            type="primary"
            text="IdP Connect"
            onClick={() =>
              (window.location.href = "/idp-connect")
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ConnectedIAMs;
