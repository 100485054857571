import React, { useState } from "react";
import { IoMdPlay, IoMdPlayCircle } from "react-icons/io";
import PillButton from "../components/atoms/PillButton";
import SubmitButton from "../components/atoms/SubmitButton";
import Footer from "../components/organisms/Footer";
import HomeSection from "../components/atoms/FeatureSection";
import Navbar from "../components/organisms/navbar";

function PrivacyPolicy() {
  const [demoOpen, setDemoOpen] = useState(false);

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Effective 26th February, 2024
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Privacy Policy
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Our Privacy Policy documents how Aglide manages your personal data. Any individual that accesses our Services in any manner acknowledges that they accept the practices and policies outlined in it, and hereby consents to their information being collected, used, and shared as described in it.
          </h3>
          <div className="mt-8 flex gap-4"></div>
        </div>
      </div>

      <div className="flex flex-col gap-36 w-full">
        <div className="max-w-7xl mx-auto flex flex-col gap-2 w-full">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              Contents
            </h1>
            <ol className="list-decimal pl-6 whitespace-normal text-md font-light text-darkgrey">
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#functionalities"
                >
                  Functionalities
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#transfer-and-storage"
                >
                  Transfer and storage of Users personal content
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#google-gmail-data"
                >
                  Google Gmail Data
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#automatically-collected-information"
                >
                  Automatically Collected Information
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#use-of-information"
                >
                  Use of Information
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#sharing-of-information"
                >
                  Sharing of Information
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#analytics-services"
                >
                  Analytics Services Provided by Others
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#data-retention"
                >
                  Data Retention
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#transfer-of-information"
                >
                  Transfer of Information to the U.S. and Other Countries
                </a>
              </li>
              <li>
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="#your-choices"
                >
                  Your Choices
                </a>
              </li>
            </ol>
            <p className="whitespace-normal text-md font-light text-darkgrey pt-8">
              This Privacy Policy explains how we at Aglide Inc. (“Aglide,” “we,” “our,” “us”) collect, use, and disclose information (“Information”, “Data”) about any third party (“User”, “Organisation”, “They”, “Their”, “Them”, “You”) through our products (“App”, “Website”, “Cloud Services”, “Extension”) that link to this Privacy Policy (collectively, the “Services”).
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              This privacy policy applies only to the actions of Aglide and Users with respect to Aglide’s Services. It does not extend to any websites that can be accessed from Aglide’s Services including, but not limited to, any links we may provide to social media websites. For purposes of the applicable Data Protection Laws, Aglide is the "data controller". This means that Aglide determines the purposes for which, and the manner in which, User Data is processed.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              We may change this Privacy Policy from time to time. If we make changes, we will notify Users by revising the date at the top of the policy and, in some cases, we may provide Users with additional notice (such as adding a statement to our homepage or sending them a notification). We encourage Users to review the Privacy Policy whenever Users access the Services or otherwise interact with us to stay informed about our information practices and the choices available to the Users.
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              By using the Aglide App, Users are asking the Aglide App to access and store their personal Data. This is what we call “processing of your personal data”. Before using the Aglide App, please read this privacy policy carefully and agree to its terms to continue using the Aglide App. If Users do not agree with this privacy policy, please do not use the Aglide App.
            </p>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black" id="functionalities">
              Functionalities
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              The Aglide App privately stores information about Users internet accounts, including usernames, passwords, MFA codes, and addresses. The Aglide app may change information in Users’ accounts when prompted to by Users or their Organisation. Aglide only processes User Data because they or their Organisation have consented to it and/or because we are performing a service they have requested from us.
            </p>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="transfer-and-storage">
                Transfer and storage of Users personal content
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Login credentials for any of the accounts Users choose to store with Aglide, i.e., usernames and passwords, 2nd factor auth codes, service names, addresses, and credit card details, only ever appear in plain text locally on the Users extension or browser. User’s private data is encrypted with encryption keys only accessible to Users or their Organisation before being stored. Aglide cloud services do not have access to sensitive User information.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                User’s private data cannot be decrypted without knowledge of the User’s or their Admin’s password, so in no event will private User data be deciphered by Aglide.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Outside of the context of private User data, Aglide attempts to collect as little personally identifiable information (PII) about Users or their Organisation as possible. Aglide collects Users’ email addresses, if they provide it, in order to communicate with them and in order to help them access the correct data when logging in. The only sensitive information the Aglide cloud service has access to in plaintext is Users email, and this information is encrypted with details only accessible by Aglide.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Aglide endeavours to assure that any collection of Service analytics, metrics, and debugging and troubleshooting information is anonymized in a way that Aglide cannot link to User PII. If Users consent, the app will associate User’s email with this information so that we may communicate with Users to troubleshoot issues.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="google-gmail-data">
                Google Workspace Data
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                If Users explicitly grant consent, and only thereafter, Aglide will retrieve information such as MFA codes from specific emails from Users’ Gmail to assist in account management and sign in. To do so, Aglide requests a limited set of Google Workspace permissions.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                The data obtained from Users is never shared with or transferred to anybody including Aglide and any vendors, consultants, and/or other 3rd party service providers. User’s authorisation tokens are encrypted using the User’s server encryption key, so only accessible to them.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Aglide’s use and transfer of information received from Google APIs
                fully adheres to the{" "}
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                >
                  Google API Services User Data Policy
                </a>
                , including the Limited Use requirements.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="automatically-collected-information">
                Automatically Collected Information
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                When Users access or use our Services, we automatically collect some
                information about them, including, but not limited to:
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Log Information:</strong> We collect log information about
                Users’ use of the Services, including their interaction with our
                Services, and diagnostic logs.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Website Information:</strong> We collect information about
                Users visit to our Website. This information helps us to make
                improvements to Website content and navigation, and includes Users’
                IP address, the date, times and frequency with which Users access
                the Website and the way they use and interact with its content.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Browsing Information:</strong> Through the Extension we may
                collect the following Data, which includes personal Data, from
                Users: Personal identifiable information, such as email address;
                Domains and URLs the browser interacts with; Webpage content, such
                as text and links; Authentication information, such as credentials,
                PINs and passwords; Users activity such as network monitoring,
                clicks, mouse position, scroll or keystroke logging;
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto w-full">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black" id="use-of-information">
              Use of Information
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              We use the information we collect to provide, maintain, and improve
              our Services. We may also use the information we collect to:
            </p>
            <ul className="list-disc list-inside whitespace-normal text-md font-light text-darkgrey">
              <li>
                Send Users technical notices, updates, security alerts, and support
                and administrative messages and to respond to Users comments,
                questions, and customer service requests;
              </li>
              <li>
                Communicate with Users about products, services, offers, and events
                offered by Aglide and others, and provide news and information we
                think will be of interest to them;
              </li>
              <li>
                Monitor and analyze trends, usage, and activities in connection with
                our Services;
              </li>
              <li>
                Detect, investigate and prevent fraudulent transactions and other
                illegal activities and protect the rights and property of Aglide and
                others;
              </li>
              <li>
                Carry out any other purpose described to Users at the time the
                information was collected.
              </li>
            </ul>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black" id="sharing-of-information">
              Sharing of Information
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              We will not share information about Users except in the following
              circumstances or as otherwise described in this Privacy Policy:
            </p>
            <ul className="list-disc list-inside whitespace-normal text-md font-light text-darkgrey">
              <li>
                In response to a request for information if we believe disclosure is
                in accordance with, or required by, any applicable law or legal
                process, including lawful requests by public authorities to meet
                national security or law enforcement requirements;
              </li>
              <li>
                If we believe Users actions are inconsistent with our user
                agreements or policies, or to protect the rights, property, and
                safety of Aglide or others;
              </li>
              <li>
                In connection with, or during negotiations of, any merger, sale of
                company assets, financing or acquisition of all or a portion of our
                business by another company;
              </li>
              <li>
                Between and among Aglide and our current and future parents,
                affiliates, subsidiaries, and other companies under common control
                and ownership; and
              </li>
              <li>With Users’ consent or at direction.</li>
              <li>
                We may also share aggregated or de-identified information, which
                cannot reasonably be used to identify Users.
              </li>
            </ul>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              Aglide may, from time to time, expand or reduce our business and this
              may involve the sale and/or the transfer of control of all or part of
              Aglide. Data provided by Users will, where it is relevant to any part
              of our business so transferred, be transferred along with that part
              and the new owner or newly controlling party will, under the terms of
              this privacy policy, be permitted to use the Data for the purposes for
              which it was originally supplied to us. We may also disclose Data to a
              prospective purchaser of our business or any part of it. In the above
              instances, we will take steps with the aim of ensuring User privacy is
              protected.
            </p>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="analytics-services">
                Analytics Services Provided by Others
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                We may allow others to provide analytics Services on our behalf across
                the web. These entities may use cookies, web beacons, and other
                technologies to collect information about User’s use of the Services
                and other websites and applications, including their IP address, web
                browser, pages viewed, time spent on pages or in apps, links clicked,
                and conversion information. This information may be used by Aglide and
                others to, among other things, analyze and track data, determine the
                popularity of certain content, and better understand their online
                activity with respect to our product and business.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="analytics-services">
                Data Retention
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                We store the Information we collect on Users for as long as is
                necessary for the purpose for which we originally collected it, or for
                other legitimate business purposes, including to meet our legal,
                regulatory, or other compliance obligations.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="transfer-of-information">
                Transfer of Information to the U.S. and Other Countries
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                Aglide is based in the United States. Data which we collect from Users
                may be stored and processed in and transferred to countries outside of
                the UK and European Economic Area (EEA). For example, this could occur
                if our servers are located in a country outside the UK or EEA or one
                of our service providers is situated in a country outside the UK or
                EEA. We will only transfer Data outside the UK or EEA where it is
                compliant with data protection legislation and the means of transfer
                provides adequate safeguards in relation to their data, eg by way of
                data transfer agreement, incorporating the current standard
                contractual clauses adopted by the European Commission. To ensure that
                User Data receives an adequate of protection, we have put in place
                appropriate safeguards and procedures with the third parties we share
                User Data with. This ensures User Data is treated by those third
                parties in a way that is consistent with the Data Protection Laws.
              </p>
            </div>
            <div className="flex w-full flex-col gap-2 lg:gap-4 mt-4">
              <h1 className="whitespace-normal text-xl font-medium text-black" id="transfer-of-information">
                Your Choices
              </h1>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Account Information: </strong>
                Users may update or correct certain Information about them at any time
                by logging into their account, or they can email us at{" "}
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="mailto:privacy@aglide.com"
                >
                  privacy@aglide.com
                </a>{" "}
                and submit a request. If Users wish to deactivate their account, they
                can email us at{" "}
                <a
                  className="underline hover:opacity-50 cursor-pointer"
                  href="mailto:privacy@aglide.com"
                >
                  privacy@aglide.com
                </a>
                , but note that we may retain certain Information as required by law
                or for legitimate business purposes. We may also retain cached or
                archived copies of Information about Users for a certain period of
                time.
              </p>
              <p className="whitespace-normal text-md font-light text-darkgrey">
                <strong>Cookies: </strong>
                Most web browsers are set to accept cookies by default. If Users
                prefer, they can usually choose to set their browser to remove or
                reject browser cookies. Please note that if Users choose to remove or
                reject cookies, this could affect the availability and functionality
                of our Services.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto w-full mb-36">
          <div className="flex w-full flex-col mt-8 gap-2 lg:gap-4">
            <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
              Contact Us
            </h1>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              If Users have any questions about this Privacy Policy, please contact
              us at:
            </p>
            <p className="whitespace-normal text-md font-light text-darkgrey">
              Aglide Inc.,
              <br />
              1111B S Governors Ave #6311,
              <br />
              Dover,
              <br />
              DE 19904
              <br />
              United States
              <br />
              <a
                className="underline hover:opacity-50 cursor-pointer"
                href="mailto:privacy@aglide.com"
              >
                privacy@aglide.com
              </a>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
