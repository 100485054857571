import React, { useState, useRef, useEffect, FormEvent } from "react";
import { InlineWidget } from "react-calendly";
import { useNavigate } from "react-router-dom";
import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";
import SavingsCalculator from "../components/organisms/SavingsCalculator";
import { Helmet } from "react-helmet";
import SubmitButton from "../components/atoms/SubmitButton";

function Savings() {
  const formRef = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleSubmit = (event: Event) => {
      event.preventDefault();
      const formData = new FormData(formRef.current as HTMLFormElement);

      fetch(
        "https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&id=5293e68a78&f_id=007fcbe5f0",
        {
          method: "POST",
          body: formData,
        }
      )
        .then((response) => {
          console.log("Form submitted successfully!", response);
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
      navigate("/book-onboarding");
    };

    if (formRef.current) {
      formRef.current.addEventListener("submit", handleSubmit);
    }

    return () => {
      if (formRef.current) {
        formRef.current.removeEventListener("submit", handleSubmit);
      }
    };
  }, []);

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Helmet>
        <title>Savings</title>
        <meta name="description" content="See how much you Aglide could save you" />
        <meta property="og:image" content="" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "Savings",
            "description": "See how much you Aglide could save you",
            "image": ""
          }
        `}
        </script>
      </Helmet>
      <Navbar />
      <div className="max-w-7xl mx-auto py-48 mt-8 flex flex-col md:flex-row gap-32">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <h3 className="mx-1 lg:text-2xl text-xl font-medium text-primary">
            Save the SSO Tax
          </h3>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Aglide is priced to save you money.
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Aglide is priced to help you save money. Usually, using Aglide SSO instead of SAML for just one app results in customers saving overall.
          </h3>
          <div className="mt-8">
            <SubmitButton
              type="primary"
              text="Get a Quote"
              onClick={() =>
                (window.location.href = "/book-demo")
              }
            />
          </div>
          <div className="mt-8 flex gap-4">
          </div>
        </div>
      </div>
      {/* <div className="max-w-7xl mx-auto pb-48 h-fit flex flex-col gap-4">
        <div className="flex md:flex-row flex-col gap-4">
          <div className="h-full w-full flex flex-col gap-6 p-8 rounded-xl bg-primary5">
            <div className="flex flex-col w-full items-center">
              <h3 className="lg:text-2xl text-xl font-medium text-primary">
                Pay as you go
              </h3>
              <h1 className="lg:text-5xl text-3xl font-medium text-black text-center">
                $2.99 per account
              </h1>
              <h3 className="lg:text-2xl text-xl text-darkgrey text-center mt-4">
                Pay per user account accessed via Aglide SSO.
              </h3>
            </div>
          </div>
          <div className="h-full w-full flex flex-col gap-6 p-8 rounded-xl bg-primary5">
            <div className="flex flex-col w-full items-center">
              <h3 className="lg:text-2xl text-xl font-medium text-primary">
                Unlimited
              </h3>
              <h1 className="lg:text-5xl text-3xl font-medium text-black text-center">
                $14.99 per user
              </h1>
              <h3 className="lg:text-2xl text-xl text-darkgrey text-center mt-4">
                With users having unlimited Aglide SSO apps.
              </h3>
            </div>
          </div>
        </div>
        <div className="h-full w-full flex flex-col gap-6 p-4 rounded-xl bg-palegrey">
          <p className="text-md font-light text-darkgrey">
            Volume discounts available - contact sales to enquire. Customers can switch plans at any time. Prices are per month and minimums may apply.
          </p>
        </div>
      </div> */}
      <div className="flex flex-col min-h-screen w-full max-w-7xl mx-auto" id="calculator">
        <SavingsCalculator />
      </div>

      <div
        className={`bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col gap-16 h-fit max-w-5xl mx-auto md:flex-row w-full mb-16`}
      >
        <div className="flex-1 order-1">
          <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-4">
            Don't see your app?
          </h1>
          <p className="text-left lg:text-2xl text-lg text-darkgrey mb-4">
            Aglide can support almost any app. We already support 100s of services and build support for any apps our customers request, including their internal platforms.
          </p>
          <div className="flex flex-col sm:flex-row justify-left gap-4 mr-auto mt-6">
            <SubmitButton
              type="primary"
              text="Book a demo"
              onClick={() =>
                (window.location.href = "/book-demo")
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Savings;
