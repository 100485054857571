function RoundedSection(props: {
  image?: string;
  body: string;
  title: string;
}) {
  return (
    <div className="h-full w-full rounded-3xl align-middle mx-auto bg-primary5">
      <div className={"p-8 h-full w-fit flex flex-col align-middle mx-auto justify-between " + (props.image ? " lg:p-12" : " lg:p-10")}>
        <div className="flex flex-col gap-4">
          <h1 className="text-center lg:text-3xl text-xl font-medium text-black">
            {props.title}
          </h1>
          <p className="text-center text-l text-darkgrey">
            {props.body}
          </p>
        </div>

        <div
          className={`m-auto pt-8 w-max-[50px] align-middle` + (!props.image ? " hidden" : "")}
        >
          <img
            src={props.image}
            className="mx-auto"
            alt="onboarding"
          />
        </div>
      </div>
    </div>
  );
}

export default RoundedSection;
