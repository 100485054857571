import React, { useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";

function Footer() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrormessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  function submitForm() {
    const url =
      "https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&amp;id=5293e68a78&amp;f_id=007fcbe5f0";

    // Email validation
    const emailFormat = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/.test(email);
    if (!emailFormat) {
      setSuccessMessage("");
      setErrormessage(
        "That didn't look like a valid email. Can you double check?"
      );
      return false;
    }
    fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "mce-EMAIL": email,
      }),
    })
      .then((res) => {
        console.log(res);
        const data: any = res.json();
        return data;
      })
      .then((data) => {
        if (data["success"]) {
          setErrormessage("");
          setSuccessMessage(
            "Your email has been record. We will be in touch soon!"
          );
        } else {
          console.log(data);
          setSuccessMessage("");
          setErrormessage(data["msg"]);
        }
      });
  }

  return (
    <div className="flex flex-col gap-8 lg:mx-[-48px] mx-[-24px] mt-8 p-4 px-6 lg:px-12">
      <div className="w-full py-6 max-w-7xl mx-auto flex-col gap-6">
        <div>
          <a
            href="/"
            className="font-semibold font-sans text-3xl text-primary hover:opacity-80"
          >
            Aglide
          </a>
          <div className="flex flex-row gap-2 mt-2">
            <a
              href="https://twitter.com/UseAglide"
              target="_blank"
              rel="noreferrer"
            >
              <RiTwitterXFill className="fill-darkgrey hover:opacity-50" size={20} />
            </a>
            <a
              href="https://www.linkedin.com/company/aglide"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin
                className="fill-darkgrey hover:opacity-50"
                size={20}
              />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:flex-row justify-between mt-2">
          <div className="flex flex-col lg:order-3 flex-grow flex-1 min-w-fit">
            <h3 className="font-sans font-medium mb-2">Keep in touch</h3>
            <form
              action="https://useattic.us10.list-manage.com/subscribe/post?u=8dc1b84b1b5502de3ef42062f&amp;id=5293e68a78&amp;f_id=007fcbe5f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="flex flex-col sm:flex-row gap-2 w-full lg:max-w-md"
              target="_blank"
            >
              <input
                type="email"
                name="EMAIL"
                placeholder="Your email address"
                className="font-light text-sm px-3 py-2 text-md placeholder-grey p-4 border-[1px] border-grey rounded-lg outline-grey flex-1"
                id="mce-EMAIL"
                required
              />
              <button
                type="submit"
                className="font-light text-sm px-3 py-2 bg-primary text-white rounded-lg hover:opacity-80 border-primary border-2 hover:opactiy-80"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
              >
                Hear about updates
              </button>
            </form>
            <p className="text-xs lg:max-w-md mt-2 text-darkgrey">
              By clicking “Hear about updates” you agree to be added to our
              newsletter. Your data will be stored in accordance with our{" "}
              <a
                href="/privacy-policy"
                className=" underline hover:opacity-50 cursor-pointer"
              >
                privacy policy
              </a>
              . You may unsubscribe at any time.
            </p>
          </div>
          <div className="flex flex-col flex-grow flex-1 gap-1">
            <h3 className="font-sans font-medium">Company</h3>
            <a
              href="/security"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Security
            </a>
            <a
              href="mailto:press@aglide.com"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Press Enquiries
            </a>
            <a
              href="/privacy-policy"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Privacy Policy
            </a>
            <a
              href="/security-policy"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Security Policy
            </a>
          </div>
          <div className="flex flex-col flex-grow flex-1 gap-1">
            <h3 className="font-sans font-medium">Product</h3>
            <a
              href="/single-sign-on"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Single Sign On
            </a>
            <a
              href="/idp-connect"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              IdP Integration
            </a>
            <a
              href="/auto-migrate"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Auto Migrate
            </a>
          </div>
          <div className="flex flex-col flex-grow flex-1 gap-1">
            <h3 className="font-sans font-medium">Enquiries</h3>
            <a
              href="/savings"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Savings Calculator
            </a>
            <a
              href="/book-demo"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Book Demo
            </a>
            <a
              href="/get-started"
              className="text-darkgrey cursor-pointer hover:opacity-80"
            >
              Book Onboarding
            </a>
          </div>
        </div>
        <div className="mx-auto text-center text-darkgrey pb-4 mt-8">
          ©️ Aglide Inc. {new Date().getFullYear()}
        </div>
      </div>
    </div>
  );
}

export default Footer;
