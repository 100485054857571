import { ReactElement } from "react";

interface FeatureProps {
  image: string;
  body: string;
  title: string;
  icon: ReactElement;
  visible: boolean;
}

function FeatureSection(props: FeatureProps) {
  const { image, body, title, icon, visible } = props;

  return (
    <div className={`transition-opacity duration-500 ${visible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div
        className={`border-2 border-palegrey rounded-2xl p-6 md:p-14 flex flex-col gap-16 h-fit w-fit max-w-7xl mx-auto md:flex-row ${visible ? 'block' : 'hidden'}`}
      >
        <div className="flex-1 order-1">
          <h1 className="text-left lg:text-3xl text-xl font-medium text-primary mb-4">{icon}</h1>
          <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
            {title}
          </h1>
          <p className="text-left text-l text-darkgrey">{body}</p>
        </div>
        <div className="lg:w-1/3 md:w-1/2 w-full order-2 sm:max-w-[400px] mx-auto">
          <img src={image} alt="onboarding" />
        </div>
      </div>
    </div>
  );
}

export default FeatureSection;