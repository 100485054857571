import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Security from "./pages/Security";
import Support from "./pages/Support";
import GetStarted from "./pages/GetStarted";
import BookOnboarding from "./pages/BookOnboarding";
import BookDemo from "./pages/BookDemo";
import Savings from "./pages/Savings";
import SingleSignOn from "./pages/SingleSignOn";
import IdPConnect from "./pages/IdPConnect";
import AutoMigrate from "./pages/AutoMigrate";
import SecurityPolicy from "./pages/SecurityPolicy";
import posthog from "posthog-js";
import Research from "./pages/Research";

function App() {
  useEffect(() => {
    window.addEventListener("VectorVisitorIdCreated", (event: any) => {
      const vectorVisitorId = event.detail.vectorVisitorId;
      console.log("VectorVisitorIdCreated:", vectorVisitorId);
      posthog.identify(vectorVisitorId);
    });
    // Demoing optional event listeners: This event will be dispatched when an existing Vector Visitor ID is found
    window.addEventListener("VectorVisitorIdFound", (event: any) => {
      const vectorVisitorId = event.detail.vectorVisitorId;
      console.log("VectorVisitorIdFound:", vectorVisitorId);
      posthog.capture("VectorVisitorIdFound", { vectorVisitorId });
      posthog.identify(vectorVisitorId);
    });
  }, []);

  return (
    <div className=" min-h-screen">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/savings" element={<Savings />} />
          <Route path="/pricing" element={<Savings />} />
          <Route path="/single-sign-on" element={<SingleSignOn />} />
          <Route path="/idp-connect" element={<IdPConnect />} />
          <Route path="/auto-migrate" element={<AutoMigrate />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/book-onboarding" element={<BookOnboarding />} />
          <Route path="/book-demo" element={<BookDemo />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/security-policy" element={<SecurityPolicy />} />
          <Route path="/security" element={<Security />} />
          <Route path="/research" element={<Research />} />
          <Route path="/support" element={<Support />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
