import React from "react";

function SubmitButton(props: {
  type: "primary" | "secondary" | "tertiary";
  text: string;
  onClick: () => void;
}) {
  if (props.type === "secondary") {
    return (
      <button
        className="font-light text-md px-5 py-3 bg-transparent text-primary border-primary border-2 rounded-lg hover:opacity-80 hover:opactiy-80"
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  } else if (props.type === "tertiary") {
    return (
      <button
        className="font-light text-md px-5 py-3 bg-transparent bg-accent1 text-white border-2 rounded-lg hover:opacity-80 hover:opactiy-80"
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  } else {
    return (
      <button
        className="font-light text-md px-5 py-3 bg-primary text-white rounded-lg hover:opacity-80 hover:opactiy-80"
        onClick={props.onClick}
      >
        {props.text}
      </button>
    );
  }
}

export default SubmitButton;
