import React, { useState } from "react";

// Define the type for each service
type Service = {
  priceWithout: number;
  priceWith: number;
  iconURL: string;
  noSeats?: boolean;
};

// Define the type for the services object
type Services = {
  [key: string]: Service;
};

// Define the services object with the correct type
const services: Services = {
  Figma: {
    priceWithout: 45,
    priceWith: 12,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/figma.png",
  },
  Monday: {
    priceWithout: 27,
    priceWith: 7,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/monday.png",
  },
  Slack: {
    priceWithout: 12.5,
    priceWith: 7.25,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/slack.png",
  },
  Notion: {
    priceWithout: 15,
    priceWith: 8,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/notion.png",
  },
  Zoom: {
    priceWithout: 19.99,
    priceWith: 15.99,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/zoom.png",
  },
  Trello: {
    priceWithout: 21,
    priceWith: 10,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/trello.png",
  },
  Asana: {
    priceWithout: 60,
    priceWith: 25,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/asana.png",
  },
  Dropbox: {
    priceWithout: 26,
    priceWith: 20,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/dropbox.png",
  },
  HubSpot: {
    priceWithout: 720,
    priceWith: 266,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/hubspot.png",
  },
  "Adobe CC": {
    priceWithout: 140.99,
    priceWith: 84.99,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/adobe.png",
  },
  GitHub: {
    priceWithout: 20,
    priceWith: 4,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/github.png",
  },
  Atlassian: {
    priceWithout: 11.75,
    priceWith: 7.75,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/atlassian.png",
  },
  Canva: {
    priceWithout: 20,
    priceWith: 10,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/canva.png",
  },
  Airtable: {
    priceWithout: 60,
    priceWith: 10,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/airtable.png",
  },
  Intercom: {
    priceWithout: 202,
    priceWith: 136,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/intercom.png",
  },
  Apollo: {
    priceWithout: 79,
    priceWith: 39,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/apollo.png",
  },
  Calendly: {
    priceWithout: 25,
    priceWith: 12,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/calendly.png",
  },
  Mixpanel: {
    priceWithout: 833,
    priceWith: 20,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/mixpanel.png",
  },
  Zapier: {
    priceWithout: 600,
    priceWith: 299,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/zapier.png",
  },
  Readme: {
    priceWithout: 2000,
    priceWith: 99,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/readme.png",
  },
  Sendgrid: {
    priceWithout: 90,
    priceWith: 20,
    noSeats: true,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/sendgrid.png",
  },
  Miro: {
    priceWithout: 16,
    priceWith: 8,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/miro.png",
  },
  Box: {
    priceWithout: 15,
    priceWith: 5,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/box.png",
  },
  Docker: {
    priceWithout: 24,
    priceWith: 9,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/docker.png",
  },
  Sentry: {
    priceWithout: 80,
    priceWith: 26,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/sentry.png",
  },
  Postman: {
    priceWithout: 49,
    priceWith: 14,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/postman.png",
  },
  Loom: {
    priceWithout: 45,
    priceWith: 12.5,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/loom.png",
  },
  ClickUp: {
    priceWithout: 25,
    priceWith: 5,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/clickup.png",
  },
  Zeplin: {
    priceWithout: 21.5,
    priceWith: 10.75,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/zeplin.png",
  },
  Front: {
    priceWithout: 99,
    priceWith: 19,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/front.png",
  },
  Lucidchart: {
    priceWithout: 20,
    priceWith: 9,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/lucidchart.png",
  },
  Linear: {
    priceWithout: 14,
    priceWith: 8,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/linear.png",
  },
  DocuSign: {
    priceWithout: 50,
    priceWith: 25,
    iconURL:
      "https://aglide-public-assets.s3.eu-west-1.amazonaws.com/service-logos/docusign.png",
  },
};

const SavingsCalculator: React.FC = () => {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [userCounts, setUserCounts] = useState<{ [key: string]: number }>({});
  const [defaultUserCount, setDefaultUserCount] = useState(125);  // State to hold the default user count

  const handleServiceChange = (service: string) => {
    setSelectedServices((prev) => {
      if (prev.includes(service)) {
        const filteredServices = prev.filter((s) => s !== service);
        const newUserCounts = { ...userCounts };
        delete newUserCounts[service];
        setUserCounts(newUserCounts);
        return filteredServices;
      } else {
        setUserCounts(prev => ({ ...prev, [service]: 25 }));  // Use default user count when adding a new service
        return [...prev, service];
      }
    });
  };

  const handleUserCountChange = (service: string, count: number) => {
    setUserCounts((prev) => ({ ...prev, [service]: count }));
  };

  const totalOldPrice = selectedServices.reduce(
    (acc, service) => {
      const serviceData = services[service];
      const userCount = serviceData.noSeats ? 1 : (userCounts[service] || 0);  // Use 1 for noSeats services to ignore multiple users
      return acc + (serviceData.priceWithout - serviceData.priceWith) * userCount;
    },
    0
  );
  const totalUsers = selectedServices.reduce(
    (acc, service) => acc + (userCounts[service] || 0),  // Total user count for new price calculations
    0
  );

  // Two different pricing models based on total user count
  const totalNewPriceA = totalUsers * 2.99;
  const totalNewPriceB = defaultUserCount * 14.99;

  // Choose the minimum of the two new prices
  const totalNewPrice = Math.min(totalNewPriceA, totalNewPriceB);

  const totalSavings = totalOldPrice - totalNewPrice; // Calculate total savings

  const formatNumber = (number: number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <div className="w-full mt-4 lg:mt-8">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
          <div className="h-[575px] flex-grow flex flex-col gap-6 overflow-hidden border border-2 border-palegrey p-8 pb-0 rounded-xl">
            <input
              type="text"
              placeholder="Search for an app"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="text-md placeholder-grey p-4 border-[1px] border-grey rounded-lg outline-grey outline-[1px] w-full"
            />
            <div className="flex-grow overflow-y-auto">
              <div className="grid gap-4 grid-cols-[repeat(auto-fill,minmax(75px,1fr))]">
                {Object.keys(services)
                  .filter((service) =>
                    service.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .sort()
                  .map((service) => (
                    <div
                      key={service}
                      className={`flex flex-col items-center cursor-pointer p-2 gap-2 rounded-md hover:bg-primary5 box-content ${selectedServices.includes(service) ? "border border-grey" : "border border-white"
                        }`}
                      onClick={() => handleServiceChange(service)}
                    >
                      <img src={services[service].iconURL} alt={service} className="w-12 h-12 rounded-md mt-1 border border-lightgrey" />
                      <p className="text-center text-sm truncate">{service}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="lg:w-[30%] w-full flex lg:flex-col flex-col-reverse lg:h-[575px] gap-4">
            <div className="p-8 rounded-xl bg-primary5 flex flex-col justify-around">
              <p className='text-xl text-lg font-light text-darkgrey'>SAML SSO: ${formatNumber(totalOldPrice * 12)}</p>
              <p className='text-xl text-lg font-light text-darkgrey'>Aglide SSO (Est.): ${formatNumber(totalNewPrice * 12)}</p>
              <h3 className="mt-4 text-xl font-medium text-primary">Annual Savings: ${formatNumber(Math.max(0, totalSavings * 12))}</h3>
            </div>
            {/* <div className="w-full flex flex-col gap-6 p-4 rounded-xl bg-palegrey">
              <span className="text-md font-light text-darkgrey">Your optimal plan is {totalNewPrice === totalNewPriceA ? "Pay as you go" : "Unlimited"}</span>
            </div> */}
            <div className="flex-grow overflow-y-auto">
              <ul>
                <div className="w-full justify-between flex items-center mb-4">
                  <span className="text-md font-light text-darkgrey">Organisation Headcount: </span>
                  <div className="relative flex items-center">
                    <input
                      type="number"
                      min="1"
                      max="500"
                      value={defaultUserCount}
                      onChange={(e) => setDefaultUserCount(Number(e.target.value))}
                      className="w-[115px] text-md placeholder-grey p-2 border-[1px] border-grey rounded-lg outline-grey pr-12"
                    />
                    <span className="absolute right-4 text-darkgrey">Users</span>
                  </div>
                </div>
                {selectedServices.map((service) => (
                  <li key={service} className="w-full justify-between flex items-center mb-4">
                    <div className="flex items-center">
                      <img src={services[service].iconURL} alt={service} className="w-10 h-10 rounded-md mr-2 border border-lightgrey" style={{ aspectRatio: '1' }} />
                      <span className="text-md font-light text-darkgrey mr-2">{service}:</span>
                    </div>
                    <div className="relative flex items-center">
                      <input
                        type="number"
                        min="1"
                        max="500"
                        value={userCounts[service] || ''}
                        onChange={(e) => handleUserCountChange(service, Number(e.target.value))}
                        className="w-[115px] text-md placeholder-grey p-2 border-[1px] border-grey rounded-lg outline-grey pr-12"
                      />
                      <span className="absolute right-4 text-darkgrey">Users</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="text-darkgrey mt-2">
        Prices given are an indication and may be out of date. Values were quoted from vendors' websites and <a href="https://sso.tax">sso.tax</a>
      </p>
    </div>
  );
};


export default SavingsCalculator;
