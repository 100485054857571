import Footer from "../components/organisms/Footer";
import Navbar from "../components/organisms/navbar";
import ConnectedIAMs from "../components/organisms/ConnectedIAMs";
import AglideFeaturesCarousell from "../components/organisms/AglideFeaturesCarousell";
import SubmitButton from "../components/atoms/SubmitButton";
import RoundedSection from "../components/atoms/RoundedSection";

function Home() {

  return (
    <div className="min-h-full w-full overflow-hidden px-6 lg:px-12">
      <Navbar />
      <div className="h-screen min-h-[750px] max-h-[900px] max-w-7xl mx-auto py-48 mt-8">
        <div className="flex flex-col align-left lg:w-[66%] w-[100%] gap-1">
          <div className="flex flex-row items-center">
            <h3 className="text-right mx-1 lg:text-2xl text-xl font-medium text-primary">
              Backed by
            </h3>
            <img
              src="/media/YCLogo.png"
              className="lg:h-7 h-6 mr-0 ml-1"
              alt="Y Combinator"
            />
            <h3 className="text-left mx-1 lg:text-2xl text-xl font-medium text-primary">
              Combinator
            </h3>
          </div>
          <h1 className="lg:text-7xl text-4xl font-medium text-black mb-4 mt-1">
            Connect any app to your SSO, without SAML.
          </h1>
          <h3 className="lg:text-2xl text-xl text-darkgrey">
            Avoid the SSO tax and manage access to apps that don’t support SAML, with no impact on security or end-user experience.
          </h3>
          <div className="mt-8 flex gap-4">
            <SubmitButton
              type="primary"
              text="Get Started"
              onClick={() =>
                (window.location.href = "/get-started")
              }
            />
            <SubmitButton
              type="secondary"
              text="Book Demo"
              onClick={() =>
              (window.location.href =
                "/book-demo")
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full gap-48 mb-36">
        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto">
            <div className="flex lg:w-[33%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                Access management & SSO for any app.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Users can access any app with their existing SSO credentials or dashboard.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Admins can enable SSO, set conditional access policies, and revoke sessions.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Aglide SSO works just like SAML SSO.
                </p>
              </div>
              <div className="hidden lg:flex ">
                <SubmitButton
                  type="primary"
                  text="Learn More"
                  onClick={() =>
                    (window.location.href = "/single-sign-on")
                  }
                />
              </div>
            </div>

            <div
              className={`w-[100%] lg:w-[66%] overflow-visible m-auto align-middle`}
            >
              <div className="w-[1200px] hidden lg:flex">
                <img
                  src={"/media/Desktop.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >

              <img
                src={"/media/DesktopSM.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
          </div>
          <div className="flex lg:hidden w-full justify-end">
            <SubmitButton
              type="primary"
              text="Single Sign On"
              onClick={() =>
                (window.location.href = "/single-sign-on")
              }
            />
          </div>
        </div>
        <div id="sso">
          <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
            The same secure SSO, none of the SAML hassle.
          </h1>
          <div className="max-w-7xl grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 align-middle mx-auto mt-8">
            <RoundedSection
              title="Powerful conditional access policies."
              body="Control when and how end-users access their apps. Limit app access to managed devices, require VPNs, or apply georestrictions."
              image="/media/SSO/ConditionalAccess.png"
            />
            <RoundedSection
              title="Apps only accessible through Aglide."
              body="Aglide automatically sets account passwords to values end-users can't see or change, so they can only access their apps via Aglide."
              image="/media/SSO/OnlyAglide.png"
            />
            <RoundedSection
              title="Instant user access revoking."
              body="Revoke all of a user's sessions in a click - locking them out of everything from their emails to the company's social media accounts."
              image="/media/SSO/Freeze.png"
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-8">
          <h1 className="text-center lg:text-4xl text-2xl font-medium text-black">
            Designed to work with your existing provider.
          </h1>
          <ConnectedIAMs onHome={true} />
        </div>
        <div className="h-fit max-w-7xl align-middle mx-auto overflow-visible">
          <div className="w-full flex flex-col lg:flex-row align-middle mx-auto">
            <div className="flex lg:w-[40%] w-full flex-col lg:mt-8 gap-2 lg:gap-8">
              <h1 className="text-left lg:text-4xl text-2xl font-medium text-black">
                From unmanaged account to SSO app, in seconds.
              </h1>
              <div className="flex flex-col gap-4">
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  With Auto Migrate, you can convert any account into a managed SSO app in seconds.
                </p>
                <p className="text-left lg:text-2xl text-lg text-darkgrey">
                  Aglide customers can be live in a day, with no access down time.
                </p>
              </div>
              <div className="hidden lg:flex ">
                <SubmitButton
                  type="primary"
                  text="Learn More"
                  onClick={() =>
                    (window.location.href = "/auto-migrate")
                  }
                />
              </div>
            </div>

            <div
              className={`w-full lg:w-fill lg:mr-[-50px] m-auto align-middle `}
            >
              <div className="w-full hidden lg:flex">
                <img
                  src={"/media/AutoMigrate.png"}
                  className="mx-auto"
                  alt="onboarding"
                />
              </div>
            </div>
            <div
              className={`flex lg:hidden align-middle mt-8 mb-2 m-[-32px]`}
            >

              <img
                src={"/media/AutoMigrate.png"}
                className="mx-auto"
                alt="onboarding"
              />
            </div>
          </div>
          <div className="flex lg:hidden w-full justify-end">
            <SubmitButton
              type="primary"
              text="Auto Migrate"
              onClick={() =>
                (window.location.href = "/auto-migrate")
              }
            />
          </div>
        </div>
        <AglideFeaturesCarousell />
        <div className="bg-primary5 rounded-2xl p-6 md:p-14 flex flex-col lg:gap-16 gap-8 h-fit max-w-5xl mx-auto md:flex-row w-full">
          <div className="flex-1 order-1">
            <h1 className="text-left lg:text-3xl text-xl font-medium text-black mb-2">
              No more tiers. No more SSO Tax.
            </h1>
            <p className="text-left text-l text-darkgrey">
              Aglide is priced to help you save money. Usually, using Aglide SSO instead of SAML for just one app results in customers saving overall.
            </p>
            <div className="flex flex-col sm:flex-row justify-left gap-4 mr-auto mt-6">
              <SubmitButton
                type="primary"
                text="Calculate Savings"
                onClick={() =>
                  (window.location.href = "/savings")
                }
              />
              <SubmitButton
                type="secondary"
                text="Book Demo"
                onClick={() =>
                (window.location.href =
                  "/book-demo")
                }
              />
            </div>
          </div>
          <div className="md:w-1/2 w-full order-2 sm:max-w-[400px] mx-auto">
            <img src={"/media/SSOTax.png"} alt="onboarding" />
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default Home;
