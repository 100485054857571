import React, { useEffect, useState } from "react";
import PillButton from "../atoms/PillButton";
import * as HoverCard from '@radix-ui/react-hover-card';
import * as Popover from '@radix-ui/react-popover';
import { IoChevronDown, IoMenuOutline, IoCloseOutline } from "react-icons/io5";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="fixed top-0 z-40 w-full mx-[-24px] px-[24px]">
      <div className="w-4 h-8 -right-2 bg-white fixed top-0 z-40"></div>
      <div className="flex flex-row lg:mx-[-48px] mx-[-24px] px-6 lg:px-12 py-5 pt-8 lg:pt-11 justify-between fixed top-0 w-screen bg-white bg-opacity-50 backdrop-blur-sm z-40 items-center ">
        <a href="/" className="hover:opacity-80">
          <div className="relative">
            <img
              src="/media/iconText.png"
              className={`h-8 lg:h-8 mr-3 inline-block transition-opacity ${isScrolled ? "opacity-0" : "opacity-100"
                }`}
              style={{ transitionDuration: "0.5s" }}
              alt="Aglide Logo"
            />
            <img
              src="/media/icon.png"
              className={`h-8 lg:h-8 mr-3 inline-block absolute top-0 left-0`}
              alt="Aglide Logo"
            />
          </div>
        </a>
        <div className="ml-auto lg:px-12 hidden lg:flex gap-8">
          <HoverCard.Root openDelay={0} closeDelay={50}>
            <HoverCard.Trigger asChild >
              <div className="px-3 py-2 hover:bg-palegrey rounded-[4px] flex gap-1 items-center cursor-pointer">
                <p className="text-darkgrey hover:opacity-80"><IoChevronDown /></p>
                <p className="text-darkgrey hover:opacity-80">Platform</p>
              </div>
            </HoverCard.Trigger>
            <HoverCard.Portal>
              <HoverCard.Content align="start" alignOffset={-17} className="z-40 flex flex-col mt-[-4px] p-3 ml-4 mb-2 bg-white/40 backdrop-blur-md shadow-[0_0px_15px_0px_rgba(0,0,0,0.1)] rounded-[8px] gap-2 animate-fade-in-0.15" sideOffset={5}>
                <div className="flex flex-col gap-0 hover:bg-palegrey p-2 m-[-2px] rounded-[4px] cursor-pointer" onClick={() =>
                  (window.location.href = "/single-sign-on")
                }>
                  <p className="font-sans text-darkgrey font-medium">SAMLless Single Sign On</p>
                  <p className="font-sans text-darkgrey text-sm mt-[-1px]">How our SAMLless SSO works</p>
                </div>
                <div className="flex flex-col gap-0 hover:bg-palegrey p-2 m-[-2px] rounded-[4px] cursor-pointer" onClick={() =>
                  (window.location.href = "/idp-connect")
                }>
                  <p className="font-sans text-darkgrey font-medium">Aglide + Your Identity Provider</p>
                  <p className="font-sans text-darkgrey text-sm mt-[-1px]">Add Aglide apps to your existing SSO</p>
                </div>
                <div className="flex flex-col gap-0 hover:bg-palegrey p-2 m-[-2px] rounded-[4px] cursor-pointer" onClick={() =>
                  (window.location.href = "/auto-migrate")
                }>
                  <p className="font-sans text-darkgrey font-medium">Migrating to Aglide SSO </p>
                  <p className="font-sans text-darkgrey text-sm mt-[-1px]">Unmanaged accounts to SSO apps in seconds</p>
                </div>
              </HoverCard.Content>
            </HoverCard.Portal>
          </HoverCard.Root>

          <div className="px-3 py-2 hover:bg-palegrey rounded-[4px] flex gap-1 items-center cursor-pointer" onClick={() =>
            (window.location.href = "/security")
          }>
            <p className="text-darkgrey cursor-pointer hover:opacity-80">Security</p>
          </div>
          <div className="px-3 py-2 hover:bg-palegrey rounded-[4px] flex gap-1 items-center cursor-pointer" onClick={() =>
            (window.location.href = "/savings")
          }>
            <p className="text-darkgrey cursor-pointer hover:opacity-80">Savings</p>
          </div>
          <div className="px-3 py-2 hover:bg-palegrey rounded-[4px] flex gap-1 items-center cursor-pointer" onClick={() =>
            (window.location.href = "/book-demo")
          }>
            <p className="text-darkgrey cursor-pointer hover:opacity-80">Book Demo</p>
          </div>
        </div>
        <div className="gap-4 hidden lg:flex items-center">
          <PillButton
            type="secondary"
            onClick={() =>
              (window.location.href = "https://dashboard.aglide.com/login")
            }
            text="Sign In"
          />
          <PillButton
            type="primary"
            onClick={() =>
              (window.location.href = "/get-started")
            }
            text="Get Started"
          />
        </div>
        <div className="gap-4 flex lg:hidden items-center">
          <PillButton
            type="primary"
            onClick={() =>
              (window.location.href = "/book-demo")
            }
            text="Book Demo"
          />

          <div className="block lg:hidden text-darkgrey">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="p-2">
              {isMenuOpen ? <IoCloseOutline size="32" /> : <IoMenuOutline size="32" />}
            </button>
          </div>
        </div>

      </div >
  
      {isMenuOpen && (
        <div className="bg-white pt-[100px] backdrop-blur-sm mx-[-24px] h-[100vh] w-[calc(100%+48px)] p-4 flex lg:hidden flex-col z-40 animate-fade-in-0.15">
          <div className="flex flex-col w-full gap-4">
            {/* Platform Section */}
            <div className="flex flex-col w-full">
              <div className="flex flex-col gap-0 p-2 rounded-[4px]">
                <p className="font-sans text-darkgrey font-medium text-2xl">Platform</p>
              </div>
              <div className="flex flex-col gap-0 hover:bg-palegrey p-2 rounded-[4px] cursor-pointer" onClick={() => window.location.href = "/single-sign-on"}>
                <p className="font-sans text-darkgrey text-lg">SAMLless Single Sign On</p>
              </div>
              <div className="flex flex-col gap-0 hover:bg-palegrey p-2 rounded-[4px] cursor-pointer" onClick={() => window.location.href = "/idp-connect"}>
                <p className="font-sans text-darkgrey text-lg">Add Aglide apps to your existing SSO</p>
              </div>
              <div className="flex flex-col gap-0 hover:bg-palegrey p-2 rounded-[4px] cursor-pointer" onClick={() => window.location.href = "/auto-migrate"}>
                <p className="font-sans text-darkgrey text-lg">Migrating to Aglide SSO</p>
              </div>
            </div>

            {/* Other Sections */}
            <div className="flex flex-col gap-0 hover:bg-palegrey p-2 rounded-[4px] cursor-pointer" onClick={() => window.location.href = "/security"}>
              <p className="font-sans text-darkgrey font-medium text-2xl">Security</p>
            </div>
            <div className="flex flex-col gap-0 hover:bg-palegrey p-2 rounded-[4px] cursor-pointer" onClick={() => window.location.href = "/savings"}>
              <p className="font-sans text-darkgrey font-medium text-2xl">Savings</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
